import { Component } from 'react'
import styles from './Area.module.css'
import gearWhite from '../images/gear-white.svg'
import gearBlue from '../images/gear-blue.svg'
import insertIcon from '../images/plus.svg'
import infoIcon from '../images/info.svg'
import widgetDefinitions from './widgets'
import deleteIcon from '../images/cross.svg'
import Widget from './Widget'
import Dialog from './Dialog'
import { Button, FormGroup } from 'reactstrap'
import FormField from './FormField'
import PublishUtils from '../helpers/PublishUtils'
import { Grow } from '@mui/material'
import { isUserActionsDisabled } from "../redux/current-viewer"
import { connect } from "react-redux"
import uniqueId from "lodash.uniqueid"
import { WidgetWrapper } from "./WidgetWrapper"
import { DEFAULT_WIDGET_CONFIG } from "./default-widget-config"
import { LargeTooltip } from "./mui/LargeTooltip"
import { interpolateSubscriptions } from '../helpers/widget'
import { store } from "../store"

class Area extends Component {
  state = {
    dialogVisible: false,
    originalConfig: {},
  }

  constructor(props) {
    super(props)
    try
    {
      this.widgetId = uniqueId(`${props.config.widget}-`)
    }
    catch(e){}
  }

  componentWillUnmount() {
    // Make sure to revoke the vote when unmounting
    if (this.hasVotedForUserActionsDisabled()) {
      this.handleVoteForUserActionsDisabled(false)
    }
  }

  onInsertLayout = (name) => {
    let config = {
      ...this.props.config,
    }
    var split = null
    if (
      window.confirm(
        'Do you want this widget to be the first element in the new splitlayout'
      )
    ) {
      split = {
        areaSizes: ['auto', 'auto'],
        areas: [config, {}],
        direction: 'column',
        widget: 'SplitLayout',
      }
    } else {
      if (
        window.confirm(
          'Do you want this widget to be the second element in the new splitlayout'
        )
      ) {
        split = {
          areaSizes: ['auto', 'auto'],
          areas: [{}, config],
          direction: 'column',
          widget: 'SplitLayout',
        }
      } else {
        if (
          window.confirm(
            'Do you want this widget to be the third element in the new splitlayout or cancel all'
          )
        ) {
          split = {
            areaSizes: ['auto', 'auto', 'auto'],
            areas: [{}, {}, config],
            direction: 'column',
            widget: 'SplitLayout',
          }
        } else {
          return
        }
      }
    }

    this.props.onChange(split, this.props.index)
  }

  
  onDeleteWidget = (name) => {
    if (window.confirm('are you sure that you want to delete this?')) {
      let config = {
        ...this.props.config,
      }

      config = {}
      this.props.onChange(config, this.props.index)
    }
  }

  isLayoutWidget(widget) {
    if (widget === 'SplitLayout' || widget === 'SplitLayoutTest') {
      return true
    }

    if (widget === 'BBKVKProductTemplate') {
      return true
    }
    // if (widget=='Tab')  {   return true; }

    if (widget === 'BB ButtonPopup') {
      return true
    }

    return false
  }

  onFormChange = (name, value) => {
    // console.log("form change ",name,value);

    let config = {
      ...this.props.config,
      [name]: value,
    }

    if (name === 'widget') {
      //console.log(value);
      if (this.isLayoutWidget(value)) {
        // zodat we geen rare invoegsels krijgen dat doen we via de plus knop

        config.direction = 'column'
        config.areas = [this.props.config]
        if (value === 'SplitLayoutTest') {
          config.areaSizes = ['1', '1']
        } else {
          config.areaSizes = ['200px', '1']
        }
      } else if (value === 'Tab' || value === 'BBSideMenu') {
        config.tabs = ['Tab 1', 'Tab 2']
        config.areas = [this.props.config]
      } else {
        let moveConfig =
          config.areas &&
          config.areas.find((area) => area && area.widget === value)
        if (moveConfig) config = moveConfig
      }

      for (const key of Object.keys(DEFAULT_WIDGET_CONFIG.general)) {
        if (!(key in config)) config[key] = DEFAULT_WIDGET_CONFIG.general[key]
      }
      if (config.widget in DEFAULT_WIDGET_CONFIG) {
        for (const key of Object.keys(DEFAULT_WIDGET_CONFIG[config.widget])) {
          if (!(key in config)) config[key] = DEFAULT_WIDGET_CONFIG[config.widget][key]
        }
      }
    }
    this.props.onChange(config, this.props.index)
  }

  onChildChange = (config, index) =>
   {
    try 
    {
    let newConfig = {
      ...this.props.config,
    }
    
    newConfig.areas[index] = config
    this.props.onChange(newConfig, this.props.index)
  }
  catch(e)
  {
    console.log(e);
  }
    
  }

  onShowPopupInfo = () => {
    this.setState({ popupInfoVisible: true })
    let me = this
    setTimeout(function () {
      me.infoPopupClose()
    }, 2500)
  }

  infoPopupClose = () => {
    this.setState({ popupInfoVisible: false })
  }

  openAndSaveOriginalConfigForUndo = () => {
    this.setState({ dialogVisible: true, originalConfig: { ...this.props.config } })
  }

  closeAndKeepConfigChanges = () => {
    this.setState({ dialogVisible: false })
    this.props.save()
  }

  closeAndUndoConfigChanges = () => {
    this.props.onChange(this.state.originalConfig, this.props.index)
    this.setState({ dialogVisible: false })
  }

  /**
   * Not subscribing to this value from the store, for performance reasons.
   * In the conventional manner we would use mapStateToProps, but that triggers unnecessary cascading rerenders.
   * @return {boolean}
   */
  hasVotedForUserActionsDisabled() {
    return store.getState().currentViewer.votersForGlobalUserActionsDisabled.has(this.widgetId)
  }

  /**
   * @param userActionsDisabled {boolean}
   */
  handleVoteForUserActionsDisabled = (userActionsDisabled) => {
    this.props.dispatch({
      type: "VOTE_USER_ACTIONS_DISABLED",
      data: {
        voterId: this.widgetId,
        value: userActionsDisabled
      }
    })
  }

  renderWidget(config, widgetDefinition) {
    if (!config.widget) {
      if (this.props.mode === 'edit') return <div>Selecteer een widget.</div>
      return
    }
    if ((this.props.mode === 'edit') && (widgetDefinition==null)) return <div>Selecteer een widget.</div>
    if (widgetDefinition==null) return null;
    if (interpolateSubscriptions(this, config.hidden, 'boolean')) return null

    const widgetUserActionsDisabled = !!config.syncWithUserActionsDisabled && this.props.userActionsDisabled

    return (
      <WidgetWrapper
        userActionsDisabled={widgetUserActionsDisabled}
        clickable={!!this.props.onClick}
        onClick={this.props.onClick}
      >
        <Widget
          reauth={this.props.reauth}
          widgetId={this.widgetId}
          repoURL={this.props.repoURL}
          parameterValues={this.props.parameterValues}
          localRepoURL={this.props.localRepoURL}
          mode={this.props.mode}
          save={this.props.save}
          sparqlErrorMessage={this.props.sparqlErrorMessage}
          withinSelectedTab={this.props.withinSelectedTab}
          parseUrlProperties={this.props.parseUrlProperties}
          {...withTopicValues(config, widgetDefinition, this)}
          definition={widgetDefinition}
          onChange={this.onChildChange}
          closeDialog={this.props.closeDialog}
          userActionsDisabled={widgetUserActionsDisabled}
          voteForUserActionsDisabled={this.handleVoteForUserActionsDisabled}
        >
          <widgetDefinition.component />
        </Widget>
      </WidgetWrapper>
    )
  }

  render() {
   // if (this.props.withinSelectedTab==false) return false;

  // console.log(this);
    let config = this.props.config || {}
   




    let menubar = false
    let titleMenuBar = config.titleMenubar
    if (titleMenuBar != null) {
      titleMenuBar = titleMenuBar.trim()
      titleMenuBar = PublishUtils.processStringForParameters(this, titleMenuBar)
    }
    if (titleMenuBar != null && titleMenuBar !== '' && titleMenuBar !== ' ') {
      menubar = true
    }
    //  console.log("titleMenubar '"+titleMenuBar+"'");

    let mode = this.props.mode
    let widgetDefinition = widgetDefinitions.find(
      (widgetDefinition) => widgetDefinition.name === config.widget
    )
    let islayout = this.isLayoutWidget(config.widget)
    try {
      var queryDef=widgetDefinition.variables.find(obj => obj.name === "query");
    if  (queryDef!=null)
    {
      //console.log("widget with query parameter")
      if (config.query==null)
      {
            config.query=queryDef.defaultQuery;
         //   console.log("setting default query");
      }
    }
  }
  catch(e){}





    let extraStyling1 = ''
    let extraStyling2 = ''
    let extraStyling3 = ''
    let extraStyling4 = ''
    if (config.panelstyleExtra != null) {
      var sts = config.panelstyleExtra.split(' ')
      try {
        extraStyling1 = sts[0]
      } catch (e) {}
      try {
        extraStyling2 = sts[1]
      } catch (e) {}
      try {
        extraStyling3 = sts[2]
      } catch (e) {}
      try {
        extraStyling4 = sts[3]
      } catch (e) {}
    }
    let displayFlex = ' ' + styles.outerAreaContainerDisplayFlex
    //not used
    if (this.props.disableFlexbox) {
      //  console.log("disable flexbox for this area")  ;
      displayFlex = ''
    }
    let infoMenubarb = false
    if (config.infoMenubar != null) {
      if (config.infoMenubar.length > 2) {
        infoMenubarb = true
      }
    }
    var appear=true;
    var hidden = config.hidden;
    if (hidden==null) hidden=false;
    if (hidden!=false) {
    
      hidden=PublishUtils.processStringForParameters(this,hidden);
      try {hidden=JSON.parse(hidden.toLowerCase());} catch(e){}
      //console.log("2",hidden,mode);
      //if (hidden==true) console.log("3 hidden=true");
      //if (hidden==false) console.log("4 hidden=false");
     
    }
    var hidden2= config.hiddenwhenparametersareempty;
    if (hidden2!=null)
    {
      
      var hids2=hidden2.split(",");
    //console.log("hidden2",hids2);
      for (var n in hids2)
      {
        var hid2=hids2[n];
         if (this.props.pubsub[hid2]==null) hidden=true;
         if (this.props.pubsub[hid2]=="http://www.buildingbits.nl/reset") hidden=true;
      }
    }

    
    var visibilityState =hidden ? "hidden" : "visible";
 if (mode==='edit')  {  visibilityState="visible"; }

      


    return (
     <Grow appear={appear} timeout={1000} in={true}  style={{visibility: visibilityState}} >
        <div 
          className={
            styles.outerAreaContainer +
            ' ' +
            (shouldApplyBorderRadius(config.widget) ? '' : styles.zeroBorderRadiusWidget) +
            ' ' +
            displayFlex +
            ' ' +
            (mode === 'edit' ? '' : styles.viewMode) +
            ' ' +
            styles[config.widget] +
            ' ' +
            styles[config.panelstyle] +
            ' ' +
            styles[extraStyling1] +
            ' ' +
            styles[extraStyling2] +
            ' ' +
            styles[extraStyling3] +
            ' ' +
            styles[extraStyling4]
          }
          style={{
            '--area-border-style': config.areaBorderStyle,
            '--area-border-radius': config.areaBorderRadius,
            '--final-top-left-border-radius': config.finalTopLeftBorderRadius,
            '--final-top-right-border-radius': config.finalTopRightBorderRadius,
            '--final-bottom-left-border-radius': config.finalBottomLeftBorderRadius,
            '--final-bottom-right-border-radius': config.finalBottomRightBorderRadius,
          }}
          data-custom-styling={Boolean(config.customStyling)}
          data-final-top-left-border-radius={!!config.finalTopLeftBorderRadius}
          data-final-top-right-border-radius={!!config.finalTopRightBorderRadius}
          data-final-bottom-left-border-radius={!!config.finalBottomLeftBorderRadius}
          data-final-bottom-right-border-radius={!!config.finalBottomRightBorderRadius}
        >
          {mode === 'edit' && (
            <div className={styles.hoverToEdit}>
              <div className={styles.hoverIndicator} />
              <LargeTooltip
                placement="bottom"
                title={config.widget && `${config.widget} widget`}
              >
                <div className={styles.editArea}>
                  <img
                    src={insertIcon}
                    alt="box"
                    className={styles.insertIcon}
                    onClick={() => this.onInsertLayout(config.widget)}
                  />
                  <img
                    src={islayout ? gearBlue : gearWhite}
                    alt="gear"
                    className={styles.editIcon}
                    onClick={this.openAndSaveOriginalConfigForUndo}
                  />
                  <img
                    src={deleteIcon}
                    alt="cross"
                    className={styles.insertIcon}
                    onClick={() => this.onDeleteWidget(config.widget)}
                  />
                </div>
              </LargeTooltip>
            </div>
          )}

          {menubar && (
            <div className={styles.menubar}>
              <div className={styles.menubartxt}> {titleMenuBar}</div>
              {infoMenubarb && (
                <img
                  src={infoIcon}
                  alt="box"
                  className={styles.insertIcon}
                  onClick={() => this.onShowPopupInfo()}
                />
              )}
            </div>
          )}

          {this.renderWidget(config, widgetDefinition)}

          {this.state.popupInfoVisible && (
            <Dialog onClose={this.infoPopupClose}>
              <div>{config.infoMenubar}</div>
            </Dialog>
          )}

          {this.state.dialogVisible && (
            <Dialog>
              <FormField
                classforname={'dark-form-control' + styles.formElement}
                name="widget"
                label="Widget"
                type="select"
                options={[
                  { label: 'Selecteer een widget', value: '' },
                  ...widgetDefinitions.map((wd) => ({
                    label: wd.label,
                    value: wd.name,
                  })),
                ]}
                value={config.widget}
                onChange={this.onFormChange}
              />
              <FormField
                classforname="dark-form-control"
                name="panelstyle"
                label="panel style"
                type="select"
                options={[
                  { label: 'Selecteer een style', value: '' },
                  { label: 'standaard', value: 'standaard' },
                  { label: 'no scrollBars', value: 'noScrollBars' },
                  
                  { label: 'toolbar', value: 'toolbar' },
                  { label: 'border', value: 'border' },
                  { label: 'border 2', value: 'border2' },
                  { label: 'paper', value: 'paper' },
                  { label: 'paper 2', value: 'paper2' },
                  { label: 'padding', value: 'padding1' },
                  { label: 'padding 2', value: 'padding2' },
                  { label: 'padding 3', value: 'padding3' },
                  { label: 'none', value: 'none' },
                  { label: 'none white', value: 'noneWhite' },
                  { label: 'refresh', value: 'refresh' },
                  { label: 'three color template', value: 'threeColorTemplate' },
                ]}
                value={config.panelstyle}
                onChange={this.onFormChange}
              />
              <FormField
                name="panelstyleExtra"
                label="panel style  (noScrollBars)"
                type="text"
                value={config.panelstyleExtra}
                onChange={this.onFormChange}
              />

              <FormField
                name="titleMenubar"
                label="title menubar"
                type="text"
                value={config.titleMenubar}
                onChange={this.onFormChange}
              />
              <FormField
                name="infoMenubar"
                label="Info popup menubar"
                type="text"
                value={config.infoMenubar}
                onChange={this.onFormChange}
              />
              <FormField
                name="colorPrimaryAction"
                label='give primary action color (when panel style "three color template")'
                type="boolean"
                value={config.colorPrimaryAction}
                onChange={this.onFormChange}
              />
              <FormGroup tag="fieldset" className={styles["fieldset"]}>
                <legend>Behaviour on loading</legend>
                <FormField
                  name="showLoadingOverlay"
                  label="Show loading overlay while loading."
                  type="boolean"
                  value={config.showLoadingOverlay}
                  onChange={this.onFormChange}
                />
                <FormField
                  name="syncWithUserActionsDisabled"
                  label="User interaction disabled while other widgets are loading."
                  type="boolean"
                  value={config.syncWithUserActionsDisabled}
                  onChange={this.onFormChange}
                />
                <FormField
                  name="voteForUserActionsDisabledWhenLoading"
                  label="Widget blocks user interaction on other widgets while loading."
                  type="boolean"
                  value={config.voteForUserActionsDisabledWhenLoading}
                  onChange={this.onFormChange}
                />
              </FormGroup>
              <FormField
                name="hidden"
                label="hide this panel. Parameter must be true or false"
                type="text"
                topicValueInterpolationEnabled={true}
                value={config.hidden}
                onChange={this.onFormChange}
              />

              {shouldApplyBorderRadius(config.widget) && (
                <>
                  <FormField
                    name="finalTopLeftBorderRadius"
                    label="final top-left border radius"
                    type="text"
                    value={config.finalTopLeftBorderRadius}
                    onChange={this.onFormChange}
                  />
                  <FormField
                    name="finalTopRightBorderRadius"
                    label="final border top-right radius"
                    type="text"
                    value={config.finalTopRightBorderRadius}
                    onChange={this.onFormChange}
                  />
                  <FormField
                    name="finalBottomLeftBorderRadius"
                    label="final bottom-left border radius"
                    type="text"
                    value={config.finalBottomLeftBorderRadius}
                    onChange={this.onFormChange}
                  />
                  <FormField
                    name="finalBottomRightBorderRadius"
                    label="final bottom-right border radius"
                    type="text"
                    value={config.finalBottomRightBorderRadius}
                    onChange={this.onFormChange}
                  />
                </>
              )}
              <FormField
                name="hiddenwhenparametersareempty"
                label="comma separated parameters that need to have a value"
                type="text"
                value={config.hiddenwhenparametersareempty}
                onChange={this.onFormChange}
              />

              {config.widget &&
                widgetDefinition.variables &&
                widgetDefinition.variables.map((variable) => (
                  <FormField
                    key={variable.name}
                    {...variable}
                    value={config[variable.name]}
                    onChange={this.onFormChange}
                  />
                ))}

              <div style={{ textAlign: 'center' }}>
                <Button
                  color="secondary"
                  style={{ minWidth: '120px', marginTop: '12px' }}
                  onClick={this.closeAndUndoConfigChanges}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  style={{ minWidth: '120px', marginTop: '12px', marginLeft: '40px' }}
                  onClick={this.closeAndKeepConfigChanges}
                >
                  OK
                </Button>
              </div>
            </Dialog>
          )}
        </div>
        </Grow>
     
    )
  }
}

const mapStateToProps = function (state) {
  return {
    userActionsDisabled: isUserActionsDisabled(state.currentViewer)
  }
}

function withTopicValues(config, widgetDefinition, widget) {
  const newConfig = {...config}
  for (const variable of widgetDefinition.variables) {
    if (variable.topicValueInterpolationEnabled) {
      if (Array.isArray( newConfig[variable.name])) {
        newConfig[variable.name] = newConfig[variable.name].map(valueString => PublishUtils.processStringForParameters(widget, valueString))
      }
      else if (typeof newConfig[variable.name] === 'string') {
        newConfig[variable.name] = PublishUtils.processStringForParameters(widget, newConfig[variable.name])
      }
    }
  }
  return newConfig
}

export function shouldApplyBorderRadius(widgetName) {
  if (!widgetName) return false
  return widgetName !== 'BBParameterLinkEmptyWidget' && !widgetName.includes('SplitLayout')
}

/**
 * Workaround until proper typing
 * source: https://stackoverflow.com/a/49806135
 * @type {React.ComponentType<any>}
 */
export default connect(mapStateToProps)(Area)
